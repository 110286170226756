<template>
  <div class="MessageTemplates-detail-page w-100">
    <b-form @submit="handleValidate">
      <basic-subheader>
        <template v-slot:actions>
          <b-button
            class="btn btn-light ml-3"
            type="button"
            @click="returnPage"
          >
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Arrows/arrow-left.svg" />
            </span>
            Huỷ
          </b-button>
          <b-button class="btn btn-light ml-3" type="button" @click="resetData">
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/refresh-cw.svg" />
            </span>
            Reset dữ liệu
          </b-button>
          <template>
            <b-button class="btn btn-success ml-3" @click.stop="handleValidate">
              <span class="svg-icon">
                <inline-svg
                  :src="`/media/svg/icons/Neolex/Basic/${
                    isCreateForm ? 'plus' : 'save'
                  }.svg`"
                />
              </span>
              {{ isCreateForm ? 'Tạo mới' : ' Cập nhật' }}
            </b-button>
          </template>
        </template>
      </basic-subheader>
      <b-overlay :show="loading">
        <b-container fluid class="MessageTemplates-list-page__body mb-6 mt-6">
          <b-row>
            <b-col>
              <div class="card card-custom gutter-b w-100">
                <div class="card-body mt-0">
                  <h5 class="card-title text-success">
                    Thông tin MessageTemplates
                  </h5>
                  <b-row>
                    <!-- <b-col cols="1" v-if="form_type === 'edit'">
                      <template>
                        <h5 class="mt-4">{{ form.id }}</h5>
                        <div>
                          <span>Mã số: {{ form.id }}</span>
                        </div>
                        <div class="d-flex align-items-center mt-2">
                          <span
                            class="btn btn-active"
                            style="width: 95px; cursor: default"
                            v-if="form.status === 1"
                          >
                            Active
                          </span>
                          <span
                            class="btn btn-inactive"
                            style="width: 95px; cursor: default"
                            v-if="form.status === 0"
                          >
                            Inactive
                          </span>
                          <span
                            class="btn btn-MessageTemplates ml-3"
                            style="width: 95px; cursor: default"
                          >
                            Portal User
                          </span>
                        </div>
                      </template>
                    </b-col> -->
                    <b-col>
                      <b-row>
                        <b-row>
                          <b-col>
                            <basic-input
                              label="Name"
                              placeholder="Name"
                              name="name"
                              data-vv-As="Name"
                              :required="true"
                              v-validate="'required'"
                              :value.sync="form.name"
                              :state="validateState('name')"
                              :invalidFeedback="errors.first('name')"
                            ></basic-input>
                          </b-col>
                          <b-col>
                            <basic-select
                              label="Message Type"
                              class="lesson-edit-page__dropdown-filter"
                              placeholder="--- Chọn loại tin nhắn ---"
                              name="messageType"
                              :required="true"
                              v-validate="'required'"
                              data-vv-as="Loại tin nhắn"
                              :state="validateState('messageType')"
                              :invalidFeedback="errors.first('messageType')"
                              :options="messageTypes"
                              :value.sync="form.messageType"
                              :solid="false"
                              :allowEmpty="false"
                              trackBy="value"
                              valueLabel="text"
                              @update:value="
                                selected($event, 'messageType', messageTypes)
                              "
                            />
                          </b-col>
                          <b-col>
                            <basic-input
                              label="MessageContain"
                              placeholder="MessageContain"
                              name="messageContain"
                              data-vv-As="MessageContain"
                              :required="false"
                              v-validate="'required'"
                              :value.sync="form.messageContain"
                              :state="validateState('messageContain')"
                              :invalidFeedback="errors.first('messageContain')"
                            ></basic-input>
                          </b-col>
                        </b-row>
                        <b-row class="w-100">
                          <b-col class="max-vh-100">
                            <vue-editor id="editor" v-model="form.description">
                            </vue-editor>
                          </b-col>
                        </b-row>
                      </b-row>
                    </b-col>
                  </b-row>
                </div>
                <div
                  class="card-footer d-flex align-items-lg-center justify-content-center"
                >
                  <b-button
                    class="btn btn-light ml-3"
                    type="button"
                    @click.stop="resetData"
                  >
                    <span class="svg-icon">
                      <inline-svg
                        src="/media/svg/icons/Neolex/Basic/refresh-cw.svg"
                      />
                    </span>
                    Reset dữ liệu
                  </b-button>
                  <b-button
                    class="btn btn-success ml-3"
                    @click.stop="handleValidate"
                  >
                    <span class="svg-icon">
                      <inline-svg
                        src="/media/svg/icons/Neolex/Basic/save.svg"
                      />
                    </span>
                    {{ isCreateForm ? 'Tạo mới' : 'Cập nhật' }}
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-overlay>
    </b-form>
  </div>
</template>

<script>
import 'vue2-datepicker/index.css';
import { VueEditor } from 'vue2-editor';
export default {
  components: { VueEditor },
  props: {
    id: {
      type: String,
      default: null,
    },
    form_type: {
      type: String,
      default: 'edit',
    },
    editor: {
      type: String,
      default: '"<p>cde  gh</p>',
    },
  },
  data() {
    return {
      messageTypes: [
        { value: '0', text: 'Zalo OA' },
        { value: '1', text: 'SMS' },
        { value: '2', text: 'APP' },
      ],
      form: {
        messageType: null,
        name: null,
        messageContain: null,
      },
      file: null,
      preview: null,
      loading: false,
    };
  },
  created() {
    this.initDataInSelect();
    this.loadMessageTemplatesData();
  },
  computed: {
    nationId() {
      return this.form.nation?.id;
    },
    provinceId() {
      return this.form.province?.id;
    },
    districtId() {
      return this.form.district?.id;
    },
    isCreateForm() {
      return this.form_type === 'create' || this.form_type === 'copy';
    },
  },
  methods: {
    selected(value, formProperty, optionsArr) {
      this.form[formProperty] = value
        ? optionsArr.find((el) => el.value === value)
        : null;
      if (this.form[formProperty]) {
        this.$validator.reset({ name: formProperty });
      }
    },
    resetData() {
      this.form.name = null;
      this.form.messageType = null;
      this.form.messageContain = null;
      this.form.description = null;
    },
    async initDataInSelect() {
      try {
        this.loading = true;
        // const [forGeneratesource] = await Promise.all([])
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle error appropriately, e.g., show error message
      } finally {
        this.loading = false;
      }
    },
    mapDataSelect(response) {
      return response?.data.items.map((value) => {
        return {
          id: value?.id,
          text: value?.name ?? value?.description,
        };
      });
    },
    clear() {
      this.preview = null;
    },
    notBeforeToday(date) {
      return date > new Date();
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },
    async returnPage() {
      (await this.handleConfirm())
        ? this.$router.push({
            name: 'message_templates_list',
          })
        : null;
    },
    async handleConfirm() {
      let confirm = await this.$swal({
        text: 'Các thay đổi sẽ không được lưu trữ, bạn có chắc muốn huỷ?',
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Quay lại',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Huỷ',
            value: true,
            visible: true,
            className: 'btn-warning',
            closeModal: true,
          },
        },
      });
      return confirm;
    },
    async handleValidate() {
      let result = await this.$validator.validateAll();
      if (!result) {
        return;
      } else {
        this.submit();
      }
    },
    async submit() {
      console.log('submit', this.form);
      let payload = new FormData();
      const isCreateForm = this.isCreateForm;
      this.form.name && payload.append('name', this.form.name);
      this.form.messageType &&
        payload.append('messageType', this.form.messageType?.value);
      this.form.messageContain &&
        payload.append('messageContain', this.form.messageContain);
      this.form.description &&
        payload.append('description', this.form.description);

      let confirm = await this.$swal({
        text: `${
          isCreateForm
            ? 'Bạn có chắc muốn tạo MessageTemplates mới này không? '
            : 'Bạn có chắc muốn cập nhật thông tin MessageTemplates này hay không?'
        }`,
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Huỷ',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Xác nhận',
            value: true,
            visible: true,
            className: 'btn-success',
            closeModal: true,
          },
        },
      });
      if (confirm) {
        if (isCreateForm) {
          this.handleCreate(payload);
        } else {
          this.handleUpdate(payload);
        }
      }
    },
    async handleCreate(payload) {
      try {
        this.loading = true;
        await this.$api.post(`MessageTemplates`, payload);
        this.$toastr.s({
          title: 'Thành công!',
          msg: 'Chúc mừng, MessageTemplates mới đã được tạo!',
        });
        this.$router.push({ name: 'MessageTemplates_list' });
      } catch (e) {
        this.$toastr.e({
          title: 'Lỗi!',
          msg: e.message,
        });
      } finally {
        this.loading = false;
      }
    },
    async handleUpdate(payload) {
      payload.append('id', this.form.id);
      try {
        this.loading = true;
        const id = this.form.id;
        await this.$api.put(`MessageTemplates/${id}`, payload);
        this.$toastr.s({
          title: 'Thành công!',
          msg: 'Chúc mừng, MessageTemplates đã được cập nhật!',
        });
        this.$router.push({ name: 'message_templates_list' });
      } catch (e) {
        this.$toastr.e({
          title: 'Lỗi!',
          msg: e.message,
        });
      } finally {
        this.loading = false;
      }
    },

    async loadMessageTemplatesData() {
      if (!this.$route.params.id) return;
      try {
        this.loading = true;
        const id = this.$route.params.id;
        let { data } = await this.$api.get(`MessageTemplates/${id}`);
        this.form = data;
        let messageTypeMapping = this.messageTypes.find((item) => {
          if (item.value == this.form.messageType) {
            return item;
          }
        });
        console.log('Data', data);
        this.form.messageType = messageTypeMapping;
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi!',
          msg: error,
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.pictureInput {
  position: relative;
}
.icon-edit {
  z-index: 100;
  position: absolute;
  top: -15px;
  right: -6px;
  padding: 2px 6px;
  box-shadow: -1px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  width: fit-content;
  background-color: #fff;
}
.aterisk {
  color: #f64e60;
  font-size: 0.9rem;
}
</style>
